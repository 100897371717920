:root{
--uduxPinkYellowAuth: #F8DCC9;
--uduxGrayAuth: #FDFEFF;
--uduxGreenPrimary: #19231C;
--uduxBackgroundBlack: #000000;
--uduxYellowDeep: #BE822B;
--uduxYellowPrimary: #FBBA12;
--uduxYellowSecondary: #7b5a06;
--uduxCreamPrimary: #FDC474;
--uduxCyanPrimary:#B2E4BF;
--uduxBlackPrimary: #000000;
--uduxBlackSecondary:#0B0B0A;
--uduxWhitePrimary:#FFFFFF;
--uduxWhiteLight:#F5F5F5;
--uduxGrayPrimary:#707070;
--uduxGrayLightAlpha:#d4d4d439;
--uduxGrayLight:#C4C4C4;
--uduxGraySecondary:#272729;
--uduxDangerPrimary: #ED1651;
--uduxNavyBluePrimary: #0D0E1D;
--uduxWhiteSecondary: #D9D9D9;
--uduxCyanSecondary: #0A93A7;

--backgroundDarkLight: #191919;
--backgroundDarkMain: #0f0f0f;

--overlayLighter: rgba(0, 0, 0, 0.3);
--overlayLight: rgba(0, 0, 0, 0.4);
--overlayPrimary: rgba(0, 0, 0, 0.5);
--overlayMain: rgba(0, 0, 0, 0.6);
--overlayDark: rgba(0, 0, 0, 0.8);

--gradientPrimary: linear-gradient(45deg, var(--darkOrange) -10%, var(--navyBluePrimary) 100%);
--gradientSecondary: linear-gradient(180deg, var(--navyBluePrimary) 10.39%, var(--darkOrange) 100.38%);

--modalMain: #141414;

--grayLight: #C4C4C4;
--grayLightAlpha: #d4d4d439;
--grayPrimary: #707070;
--grayMain: #B4B4B4;
--dampWhite: #EAEAEA;
--dampWhiteLight: #EAEAEA80;
--infoPrimary: #436277;
--successPrimary: #5F7743;
--successMain: #32705c;
--errorPrimary: #ED1651;
--errorMain: #CB0010;
--yellowPrimary: #FFA800;
--yellowDeep: #BE822B;
--black: #000000;
--white: #FFF;
--textPrimary: #FCFCFC;
--textBlack: #242424;
--pinkPrimary: #F8DCC9;
--pinkMain: #FDDBC6;
--pinkDark: #EECFA9;
--creamPrimary: #FDC474;
--darkOrange: #BE822B;
--buttonPrimary: #FFA800;
--buttonPrimaryOverlay: rgba(255, 168, 0, 0.3);
--navyBluePrimary: #0D0E1D;

--highlight: #FECC9E;

--borderMain: #707070;
--borderLight: #70707061;
--borderLighter: #70707055;
--borderVLight: #55555520;

--size: 0;

--w300: 0.3px;
--w400: 0.4px;
--w500: 0.5px;
--w700: 1px;
--w800: 1.5px;
--w900: 2px;

--d: 2500ms;
--angle: 90deg;
--gradX: 100%;
--gradY: 50%;
--c1: rgba(168, 239, 255, 1);
--c2: rgba(168, 239, 255, 0.1);

/* translucent white */
--t5: rgba(255, 255, 255, 0.5);
--t8: rgba(255, 255, 255, 0.8);
--t9: rgba(255, 255, 255, 0.9);
}
