@import "~react-user-guide/dist/custom-style.css";

@font-face {
  font-family: "Bw ModelicaLight";
  src: url("/src/assets/fonts/BWModelica/BwModelica-Light.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Bw ModelicaSS01Bold";
  src: url("/src/assets/fonts/BWModelica/BwModelicaSS01-Bold.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Bw ModelicaRegular";
  src: url("/src/assets/fonts/BWModelica/BwModelica-Regular.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Bw ModelicaMedium";
  src: url("/src/assets/fonts/BWModelica/BwModelica-Medium.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Bw ModelicaBold";
  src: url("/src/assets/fonts/BWModelica/BwModelica-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Bw ModelicaExtraBold";
  src: url("/src/assets/fonts/BWModelica/BwModelicaSS01-ExtraBold.otf");
  font-weight: 900;
}

@font-face {
  font-family: "Bw ModelicaBlack";
  src: url("/src/assets/fonts/BWModelica/BwModelica-Black.otf");
  font-weight: 900;
}

@font-face {
  font-family: "BaguedeItalic";
  src: url("/src/assets/fonts/Baguede/Baguede-Italic.otf");
  font-weight: 900;
}

@font-face {
  font-family: "BaguedeBold";
  src: url("/src/assets/fonts/Baguede/Baguede-Regular.otf");
  font-weight: 900;
}

@font-face {
  font-family: "Baguede";
  src: url("/src/assets/fonts/Baguede/Baguede-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "BaguedeItalic";
  src: url("/src/assets/fonts/Baguede/Baguede-Italic.otf");
  font-weight: 900;
}

@font-face {
  font-family: "BaguedeBold";
  src: url("/src/assets/fonts/Baguede/Baguede-Regular.otf");
  font-weight: 900;
}

@font-face {
  font-family: "Baguede";
  src: url("/src/assets/fonts/Baguede/Baguede-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Baguede Italic";
  src: url("/src/assets/fonts/Baguede/Baguede-Italic.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Baguede";
  src: url("/src/assets/fonts/Baguede/Baguede-Regular.otf");
  font-weight: 900;
}

html {
  background-color: var(--uduxBlackPrimary);
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Bw Modelica", sans-serif;
}

body {
  margin: 0;
  font-family: "Bw Modelica", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--uduxBlackPrimary) !important;
}

#root {
  background-color: var(--uduxBlackPrimary);
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5, h6, h1 span, h2 span, h3 span, h4 span, h5 span, h6 span, b, span, p, a, input, small {
  font-family: "Baguede", sans-serif;
}

p {
  margin-bottom: 0;
}

span {
  font-family: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(0, 0, 0) inset !important;
  -webkit-text-fill-color: var(--textPrimary) !important;
}

input div {
  -webkit-text-fill-color: var(--textPrimary) !important;
  color: var(--textPrimary)
}

/* country code dropdown styles */
.ant-select-dropdown {
  background: #000;
  padding: 0 !important;
  width: inherit !important;
  border: 0.1rem solid var(--primary-gray);
}

.country-selector {
  width: 350px !important;
}

.country-selector .ant-select-item-option {
  font-family: "Baguede", sans-serif;
}

.ant-select-dropdown .ant-select-item-option-content {
  display: flex;
  color: var(--textPrimary);
  font-family: "Baguede", sans-serif;
  justify-content: space-between;

}
.ant-select-dropdown .ant-select-item:hover .ant-select-item-option-content {
  color: var(--uduxBlackPrimary);
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: var(--uduxYellowPrimary);
}

.ant-select-dropdown
  .ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input {
  background: none;
  border: none;
  box-shadow: none;
}

.ant-select-dropdown .ant-select-item-option-active {
  background: var(--uduxYellowPrimary);
}

.ant-select-dropdown .ant-select-selection-placeholder {
  color: white !important;
}

.ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled),
.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: var(--uduxYellowPrimary);
}

.ant-select-item-option {
  background: #000;
  color: var(--uduxWhitePrimary);
}
.ant-select-item-option:hover {
  background: var(--uduxYellowPrimary);
}
.ant-select-item-empty {
  background: var(--uduxBlackPrimary);
}
.ant-empty-description {
  color: var(--uduxWhitePrimary);
}

.ant-dropdown-menu-item:hover {
  background-color: transparent !important;
}

/* @media only screen and (min-width: 500px) {
  .ant-select-dropdown {
    min-width: 35rem !important;
    margin-top: 2rem;
  }
} */
.otp_input_style {
  border-bottom: 2px solid var(--uduxWhiteSecondary);
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  width: 45px !important;
  font-size: 20px;
  color: var(--uduxWhiteSecondary) !important;
}

@media screen and (max-width: 600px) {
  .otp_input_style {
    width: 25px !important;
  }
}

.input_error_text {
  width: 100%;
  margin-bottom: 10px;
}

.input_error_text p {
  color: var(--uduxRedPrimary);
  font-size: 0.75rem;
  text-align: left;
}
.ant-tooltip {
  display: none !important;
}

.menu_box, .menu__box__svg {
  display: flex;
  align-items: center;
  padding: 0.3rem;
}

.menu_box p, .menu__box__svg p {
  margin-left: 15px;
  margin-bottom: 0;
  color: var(--uduxWhitePrimary);
  transition: all 0.2s ease-in;
}

.menu_box:hover p {
  color: var(--uduxYellowPrimary);
}

.menu_box:hover svg path {
  fill: var(--uduxYellowPrimary);
}

.menu__box__svg:hover svg {
  fill: var(--uduxYellowPrimary) !important;
}

.ant-picker-panel-container {
  background: var(--uduxWhiteSecondary) !important;
}

.ant-btn-default {
  background-color: #faad14;
  border: 1px solid #faad14;
}

.ant-btn-primary {
  background-color: var(--uduxBlackSecondary);
  border: 1px solid var(--uduxBlackSecondary);
}
.ant-btn-default span,
.ant-btn-primary span {
  font-weight: bold;
  font-size: 12px;
  color: #000;
}

.ant-btn-primary span {
  color: #fff;
}

/* Hide Ant's default label */
.ant-form-item-label > label {
  display: none !important;
}
.ant-form .ant-form-item .ant-form-item-label {
  flex: 0 !important;
  max-width: 0 !important;
}

/* Date input */
.ant-picker-date-panel {
  background-color: var(--uduXGraySecondary) !important;
}


.volume-control-popover .ant-popover-content .ant-popover-inner {
  background: linear-gradient(rgb(3 3 3 / 90%), rgb(37 37 37));
  padding: 12px 6px 2px;
}